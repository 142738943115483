<template>
    <div class="EmailCampaignView ViewWrapper LwHaB">
        <clv-head-meta :title="$t('Email Campaign')"></clv-head-meta>

        <!-- TRANSPARENT LOCK -->
        <clv-interaction-locker :active="emailCampaignUpdating"></clv-interaction-locker>

        <!-- STATE INDICATORS / LOADERS -->
        <element-loading :active="emailCampaignGetting" :is-full-view="true" :lock-parent="true"></element-loading>
        <element-loading :active="emailCampaignDeleting" :is-full-view="true" :lock-parent="true" :text="$t('Deleting Email Campaign')"></element-loading>
        <element-loading :active="emailCampaignSending" :is-full-view="true" :lock-parent="true" :text="$t('Sending Email Campaign')"></element-loading>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found :for-view="true" v-if="emailCampaignNotFound">
            <div class="mt-2" slot="action">
                <router-link tag="a" :to="{ name: 'campaigns' }"><i class="fas fa-chevron-left"></i> {{ $t('Browse Campaigns') }}</router-link>
            </div>
        </simple-state-not-found>

        <!-- MAIN -->
        <div class="d-md-flex flex-md-column LwHaB-Main Disable-md" v-if="emailCampaign">
            <component :is="activeHeaderComponent"></component>
            <component :is="activeNavComponent"></component>
            <div class="flex-grow-1 flex-shrink-1 LwHaB-Main__Body">
                <div class="container">
                    <email-campaign-status-and-outcome></email-campaign-status-and-outcome>
                    <email-campaign-edit-warning-small-devices></email-campaign-edit-warning-small-devices>
                    <component :is="activeMainComponent"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RouterUtils from '@/common/utils/router.utils';
import EmailCampaignEditHeader from './EmailCampaignEditHeader';
import EmailCampaignEdit from './EmailCampaignEdit';
import EmailCampaignResultsHeader from './EmailCampaignResultsHeader';
import EmailCampaignStatusAndOutcome from './EmailCampaignStatusAndOutcome';
import EmailCampaignSentNavigation from './EmailCampaignSentNavigation';
import EmailCampaignSentSummary from './EmailCampaignSentSummary';
import EmailCampaignSentPreview from './EmailCampaignSentPreview';
import EmailCampaignEditWarningSmallDevices from './EmailCampaignEditWarningSmallDevices';

/**
 * EmailCampaignView : manages EmailCampaigns, display results after send.
 *
 * @author Dimitris Gkoulis
 * @createdAt 17 March 2020
 * @lastModifiedAt 7 November 2020 (clv-interaction-locker)
 */
export default {
    name: 'EmailCampaignView',
    components: {
        EmailCampaignStatusAndOutcome,
        EmailCampaignEditWarningSmallDevices
    },
    data () {
        return {
            activeHeaderComponent: null,
            activeNavComponent: null,
            activeMainComponent: null
        };
    },
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaignGetting: 'emailCampaignGetting',
            emailCampaignDeleting: 'emailCampaignDeleting',
            emailCampaignUpdating: 'emailCampaignUpdating',
            emailCampaignNotFound: 'emailCampaignNotFound',
            emailCampaign: 'emailCampaign',
            emailCampaignSending: 'emailCampaignSending'
        })

        // If true, the transparent lock div will be displayed.
        // Ensures that all actions are disabled when state is changing.
        // Update : We don't use this computed property because 'getting', 'deleting', 'sending' already have full page loaders.
        /*
        displayTransparentLock () {
            return this.emailCampaignGetting ||
                this.emailCampaignDeleting ||
                this.emailCampaignUpdating ||
                this.emailCampaignSending;
        }
        */
    },
    watch: {
        '$route': {
            handler: function (to, from) {
                const result = RouterUtils.resourceIdHelper(to, from);
                const id = result.toId;

                if (result.mustInitialize) {
                    this.$store.dispatch('emailCampaign/initializeModule', id)
                        .then(() => void 0).catch(() => void 0)
                        .finally(() => {
                            this.explainRoute();
                        });
                } else {
                    // Probably is query change.
                    this.explainRoute();
                }
            },
            immediate: true
        }
    },
    beforeDestroy () {
        // Reset store.
        this.$store.dispatch('emailCampaign/resetModule').then(() => void 0).catch(() => void 0);
    },
    methods: {
        explainRoute () {
            const instance = this.$store.getters['emailCampaign/emailCampaign'];
            const view = this.$route.query.view;

            if (instance === null || instance === undefined) {
                // We expect that another component will display an error.
                this.activeHeaderComponent = null;
                this.activeNavComponent = null;
                this.activeMainComponent = null;
                return;
            }

            if (instance.status === 'DRAFT') {
                // Set active component.
                this.activeHeaderComponent = EmailCampaignEditHeader;
                this.activeNavComponent = null;
                this.activeMainComponent = EmailCampaignEdit;

                // @future Remove query.view if exists? Ignore?
                return;
            }

            // Set active component.
            this.activeHeaderComponent = EmailCampaignResultsHeader;
            this.activeNavComponent = EmailCampaignSentNavigation;
            if (view === 'preview') {
                this.activeMainComponent = EmailCampaignSentPreview;
            } else {
                this.activeMainComponent = EmailCampaignSentSummary;
            }
        }
    }
};
</script>

<style lang="scss">
    .EmailCampaignView {
        background: #fff;
    }
    .EmailCampaignEditPreviewSection {
        position: relative;
    }
    .EmailCampaignEditPreviewSection-Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: white;
        z-index: 10;
        opacity: 0.5;
    }
</style>

<i18n>
{
    "en": {
        "Email Campaign": "Email Campaign",
        "Browse Campaigns": "Browse Campaigns",
        "Deleting Email Campaign": "Deleting Email Campaign",
        "Sending Email Campaign": "Sending Email Campaign"
    },
    "el": {
        "Email Campaign": "Εκστρατεία Email",
        "Browse Campaigns": "Περιήγηση στις Εκστρατείες",
        "Deleting Email Campaign": "Γίνεται διαγραφή της Εκστρατείας Email",
        "Sending Email Campaign": "Γίνεται αποστολή της Εκστρατείας Email"
    }
}
</i18n>
