<template>
    <div class="EmailCampaignRedirectView ViewWrapper">
        <!-- SILENCE IS GOLDEN -->
    </div>
</template>

<script>
/**
 * EmailCampaignRedirectView : behaves as proxy when we have to refresh the emailCampaign view without reloading the window.
 * This view does not render anything. It accepts an ID from params and redirects to EmailCampaignView with that ID.
 *
 * Why this view?
 * - In order to reset store and fetch the emailCampaign with new state
 * --- Example from DRAFT to IN_PROGRESS.
 * --- Example from IN_PROGRESS to COMPLETED.
 * - In order to display the inner components of EmailCampaignView based on its new state.
 *
 * Keep in mind: USE router.replace and NOT router.push
 *
 * @author Dimitris Gkoulis
 * @createdAt 17 March 2020
 */
export default {
    name: 'EmailCampaignRedirectView',
    mounted () {
        // Get emailCampaignId from route params.
        const emailCampaignId = this.$route.params.id;

        // Check emailCampaignId. If not valid, go to CampaignsView.
        if (emailCampaignId === null || emailCampaignId === undefined) {
            this.$router.replace({ name: 'campaigns' });
        }

        // Replace route.
        this.$router.replace({ name: 'email-campaign', params: { id: emailCampaignId } });
    }
};
</script>
