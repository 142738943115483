<template>
    <div class="EmailCampaignSentPreview my-4">
        <div class="gk-card p-0 border-0">
            <div class="FieldValueDisplay">
                <p class="title">{{ $t('From') }}</p>
                <p class="value">{{ sender }}</p>
            </div>
            <div class="FieldValueDisplay">
                <p class="title">{{ $t('Reply To') }}</p>
                <p class="value">{{ replyTo }}</p>
            </div>
            <div class="FieldValueDisplay mb-0">
                <p class="title">{{ $t('Subject') }}</p>
                <p class="value">{{ subject }}</p>
            </div>
        </div>
        <clv-iframe-sync :html="bodyHtmlProcessed"></clv-iframe-sync>
        <div class="border mt-4" v-if="bodyPlainProcessed">
            <pre class="mb-0">{{ bodyPlainProcessed }}</pre>
        </div>
    </div>
</template>

<script>
/**
 * Displays template and basic information for a non-draft EmailCampaign.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 15 July 2020
 * @lastModifiedAt 2 November 2020
 */
export default {
    name: 'EmailCampaignSentPreview',
    data () {
        return {
            sender: null,
            replyTo: null,
            subject: null,
            bodyHtmlProcessed: null,
            bodyPlainProcessed: null
        };
    },
    beforeMount () {
        const emailCampaign = this.$store.getters['emailCampaign/emailCampaign'];
        this.sender = emailCampaign.broadcaster.emailCampaignFromName + ' <' + emailCampaign.broadcaster.emailCampaignFromEmail + '>';
        this.replyTo = emailCampaign.broadcaster.emailCampaignReplyToName + ' <' + emailCampaign.broadcaster.emailCampaignReplyToEmail + '>';
        this.subject = emailCampaign.subject;
        this.bodyHtmlProcessed = emailCampaign.bodyHtmlProcessed;
        this.bodyPlainProcessed = emailCampaign.bodyPlainProcessed;
    }
};
</script>

<i18n>
{
    "en": {
        "From": "From",
        "Reply To": "Reply To",
        "Subject": "Subject"
    },
    "el": {
        "From": "Από",
        "Reply To": "Απάντηση σε",
        "Subject": "Θέμα"
    }
}
</i18n>
