<template>
    <div class="EmailCampaignEditPreviewSection EmailCampaignEditSettings">

        <!-- OVERLAY -->
        <div class="EmailCampaignEditPreviewSection-Overlay" v-if="editorOverlayActive('settings')"></div>

        <!-- EDITOR -->
        <div class="gk-card with-global-bg-color mb-0 border-0" v-if="editorOpen('settings')" key="EmailCampaignEditSettings-Edit">
            <div class="d-flex">
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Settings') }}</p>
                    <div class="mt-4">
                        <el-form ref="emailCampaignSettingsFormRef" :label-position="'top'" class="cltlr-form-label-style-1" @submit.native.prevent>

                            <!-- LANGUAGE -->
                            <div class="gk-card py-1 px-3 mb-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="mb-0 small weight-5">{{ $t('Language') }}</p>
                                    <el-form-item prop="language" class="mb-0">
                                        <el-select placeholder="Select Language" size="mini" v-model="formFields.languageKey" :disabled="!tenantAccountI18nAddOnEnabled">
                                            <el-option :label="'Greek (Ελληνικά)'" :value="'EL'">Greek (Ελληνικά)</el-option>
                                            <el-option :label="'English'" :value="'EN'">English</el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </div>

                            <!-- CLICK TRACKING -->
                            <div class="gk-card py-1 px-3 mb-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="mb-0 small weight-5">{{ $t('Enable Click Tracking') }}</p>
                                    <el-form-item prop="clickTracking" class="mb-0">
                                        <el-checkbox v-model="formFields.clickTracking" :disabled="true">{{ $t('Yes') }}</el-checkbox>
                                    </el-form-item>
                                </div>
                            </div>

                            <!-- PLAIN TEXT CLICK TRACKING -->
                            <div class="gk-card py-1 px-3 mb-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="mb-0 small weight-5">{{ $t('Enable Plain-Text Click Tracking') }}</p>
                                    <el-form-item prop="plainTextClickTracking" class="mb-0">
                                        <el-checkbox v-model="formFields.plainTextClickTracking" :disabled="true">{{ $t('Yes') }}</el-checkbox>
                                    </el-form-item>
                                </div>
                            </div>

                            <!-- OPEN TRACKING -->
                            <div class="gk-card py-1 px-3 mb-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="mb-0 small weight-5">{{ $t('Enable Open Tracking') }}</p>
                                    <el-form-item prop="openTracking" class="mb-0">
                                        <el-checkbox v-model="formFields.openTracking" :disabled="true">{{ $t('Yes') }}</el-checkbox>
                                    </el-form-item>
                                </div>
                            </div>

                            <!-- TRANSPARENCY AND COMPLIANCE FOOTER -->
                            <div class="gk-card py-1 px-3 mb-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="mb-0 small weight-5">{{ $t('Enable Transparency and Compliance footer') }}</p>
                                    <el-form-item prop="transparencyAndCompliance" class="mb-0">
                                        <el-checkbox v-model="formFields.transparencyAndCompliance" :disabled="true">{{ $t('Yes') }}</el-checkbox>
                                    </el-form-item>
                                </div>
                            </div>

                            <!-- CLOUTLAYER BRANDING -->
                            <div class="gk-card py-1 px-3 mb-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="mb-0 small weight-5">{{ $t('Display Cloutlayer Branding') }}</p>
                                    <el-form-item prop="productBranding" class="mb-0">
                                        <el-checkbox v-model="formFields.productBranding" :disabled="true">{{ $t('Yes') }}</el-checkbox>
                                    </el-form-item>
                                </div>
                            </div>

                        </el-form>
                    </div>
                    <div class="mt-4 mb-2">
                        <button class="btn btn-secondary" @click="submitEdit" :disabled="disableSubmitEdit">{{ $t('Save') }}</button>
                        <button class="btn btn-link" @click="discardEdit">{{ $t('Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- PREVIEW -->
        <div class="gk-card with-global-bg-color mb-0 border-0 C-D-None-md-Down" v-else key="EmailCampaignEditSettings-Preview">
            <div class="d-flex align-items-center">
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Settings') }}</p>
                </div>
                <div>
                    <button class="btn btn-sm btn-secondary-alt" @click="openEdit('settings')" :disabled="editorOpenDisabled()">{{ $t('Edit') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmailCampaignEditMixin from '@/modules/EmailCampaign/mixins/EmailCampaignEditMixin';

/**
 * EmailCampaignEditSettings : Settings preview and edit for EmailCampaignView.
 *
 * Hidden in mobile and small devices.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 */
export default {
    name: 'EmailCampaignEditSettings',
    mixins: [
        EmailCampaignEditMixin
    ],
    data () {
        return {
            // Tenant Account Information.
            tenantAccountI18nAddOnEnabled: window.cs3w.get('i18nAddOnEnabled', false),

            // Form //////////
            formFields: {
                /**
                 * LanguageKey can be changed if tenant has i18nAddOn enabled.
                 * Otherwise, the defaultLanguageKey from tenant will be set in emailCampaign.
                 */
                languageKey: 'EN',
                openTracking: true,
                clickTracking: true,
                plainTextClickTracking: false,
                productBranding: true,
                transparencyAndCompliance: true
            }
        };
    },
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaign: 'emailCampaign'
        }),
        disableSubmitEdit () {
            return false;
        }
    },
    mounted () {
        // Map data.
        this.formFields.languageKey = this.emailCampaign.settings.languageKey;
        this.formFields.openTracking = this.emailCampaign.settings.openTracking;
        this.formFields.clickTracking = this.emailCampaign.settings.clickTracking;
        this.formFields.plainTextClickTracking = this.emailCampaign.settings.plainTextClickTracking;
        this.formFields.productBranding = this.emailCampaign.settings.productBranding;
        this.formFields.transparencyAndCompliance = this.emailCampaign.settings.transparencyAndCompliance;
    },
    methods: {
        submitEdit () {
            // Validate data.
            // @help : No validation need.

            // Map data.
            this.$store.commit('emailCampaign/setEmailCampaignSettingsField', {
                field: 'languageKey',
                value: this.formFields.languageKey
            });
            this.$store.commit('emailCampaign/setEmailCampaignSettingsField', {
                field: 'openTracking',
                value: this.formFields.openTracking
            });
            this.$store.commit('emailCampaign/setEmailCampaignSettingsField', {
                field: 'clickTracking',
                value: this.formFields.clickTracking
            });
            this.$store.commit('emailCampaign/setEmailCampaignSettingsField', {
                field: 'plainTextClickTracking',
                value: this.formFields.plainTextClickTracking
            });
            this.$store.commit('emailCampaign/setEmailCampaignSettingsField', {
                field: 'productBranding',
                value: this.formFields.productBranding
            });
            this.$store.commit('emailCampaign/setEmailCampaignSettingsField', {
                field: 'transparencyAndCompliance',
                value: this.formFields.transparencyAndCompliance
            });

            // Call 'saveEdit' from Mixin.
            this.saveEdit();
        },
        discardEdit () {
            // Map data.
            this.formFields.languageKey = this.emailCampaign.settings.languageKey;
            this.formFields.openTracking = this.emailCampaign.settings.openTracking;
            this.formFields.clickTracking = this.emailCampaign.settings.clickTracking;
            this.formFields.plainTextClickTracking = this.emailCampaign.settings.plainTextClickTracking;
            this.formFields.productBranding = this.emailCampaign.settings.productBranding;
            this.formFields.transparencyAndCompliance = this.emailCampaign.settings.transparencyAndCompliance;

            // Call 'closeEdit' from Mixin.
            this.closeEdit();
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Settings": "Settings",
        "Edit": "Edit",
        "Save": "Save",
        "Cancel": "Cancel",
        "Yes": "Yes",
        "Language": "Language",
        "Enable Click Tracking": "Enable Click Tracking",
        "Enable Plain-Text Click Tracking": "Enable Plain-Text Click Tracking",
        "Enable Open Tracking": "Enable Open Tracking",
        "Enable Transparency and Compliance footer": "Enable Transparency and Compliance footer",
        "Display Cloutlayer Branding": "Display Cloutlayer Branding"
    },
    "el": {
        "Settings": "Ρυθμίσεις",
        "Edit": "Επεξεργασία",
        "Save": "Αποθήκευση",
        "Cancel": "Ακύρωση",
        "Yes": "Ναι",
        "Language": "Γλώσσα",
        "Enable Click Tracking": "Ενεργοποίηση Καταγραφής Κλικ",
        "Enable Plain-Text Click Tracking": "Ενεργοποίηση Καταγραφής Κλικ στην έκδοση Απλού-Κειμένου",
        "Enable Open Tracking": "Ενεργοποίηση Καταγραφής Αναγνώσεων",
        "Enable Transparency and Compliance footer": "Ενεργοποίηση υποσέλιδου \"διαφάνειας και συμμόρφωσης\"",
        "Display Cloutlayer Branding": "Εμφάνιση λογότυπου Cloutlayer"
    }
}
</i18n>
