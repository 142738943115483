<template>
    <div class="EmailCampaignEditWarningSmallDevices">
        <div class="mt-4" v-if="displayWarning">
            <div class="alert alert-info C-Alert text-center C-D-None-md-Up">
                <p class="mb-0">{{ $t('email_campaign_desktop_edit_1') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * A warning for mobile (or other small) devices users.
 *
 * @future Get if it's mobile from application store module which holds the context.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 5 August 2020
 * @lastModifiedAt 5 November 2020
 */
export default {
    name: 'EmailCampaignEditWarningSmallDevices',
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaign: 'emailCampaign'
        }),
        ...mapGetters('application', {
            contextDeviceHasSmallScreen: 'contextDeviceHasSmallScreen'
        }),
        displayWarning () {
            return (this.emailCampaign.status === 'DRAFT') && (this.contextDeviceHasSmallScreen === true);
        }
    }
};
</script>
