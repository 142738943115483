<template>
    <div class="EmailCampaignEditPreviewSection EmailCampaignEditContentPlain">

        <!-- MODAL OPEN CONTROL -->
        <span class="btn-basic-action-1" @click="openPlainTextEditor">{{ $t('Edit Plain-Text version') }}</span>

        <!-- MODAL EDITOR -->
        <sweet-modal :blocking="true"
                     :pulse-on-block="true"
                     :hide-close-button="true"
                     modal-theme="light"
                     overlay-theme="dark"
                     ref="customBodyPlainModalRef">
            <div slot="title">
                <p class="mb-0 weight-5 size-6">{{ $t('Edit Plain-Text version') }}</p>
            </div>
            <div class="position-relative">
                <textarea v-model="formFields.bodyPlain" class="editor-plain-text-textarea"></textarea>
            </div>
            <div slot="button">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <button class="btn btn-secondary" @click="submitEdit">{{ $t('Save') }}</button>
                        <button class="btn btn-link" @click="discardEdit">{{ $t('Cancel') }}</button>
                    </div>
                    <div>
                        <button class="btn btn-link" @click="generatePlainTextFromHtml">{{ $t('Generate from HTML') }}</button>
                    </div>
                </div>
            </div>
        </sweet-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SweetModal } from 'sweet-modal-vue';
import { NUtilitiesService } from '@/common/services/api.service';
import EmailCampaignEditMixin from '@/modules/EmailCampaign/mixins/EmailCampaignEditMixin';

/**
 * EmailCampaignEditContentPlain : Body Plain edit.
 * - Provides button to open the modal editor.
 * - Implements functionality for Body Plain edit and auto-generation.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 */
export default {
    name: 'EmailCampaignEditContentPlain',
    mixins: [
        EmailCampaignEditMixin
    ],
    components: {
        SweetModal
    },
    data () {
        return {
            // State Indicators /////////
            generatingBodyPlain: false,

            // Form //////////
            formFields: {
                bodyPlain: ''
            }
        };
    },
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaign: 'emailCampaign'
        })
    },
    mounted () {
        // Map data.
        this.formFields.bodyPlain = this.emailCampaign.bodyPlain;
    },
    methods: {
        submitEdit () {
            // Validate data.
            // @future : Validate somehow the bodies.

            // Map data.
            this.$store.commit('emailCampaign/setEmailCampaignField', {
                field: 'bodyPlain',
                value: this.formFields.bodyPlain
            });

            // Call 'saveEdit' from Mixin.
            this.saveEdit().then(() => {
                // Close plainTextEditor.
                this.closePlainTextEditor();
            }).catch(() => {});
        },
        discardEdit () {
            // Map data.
            this.formFields.bodyPlain = this.emailCampaign.bodyPlain;

            // Call 'closeEdit' from Mixin.
            this.closeEdit();

            // Close plainTextEditor.
            this.closePlainTextEditor();
        },

        // Editing Handlers //////////
        generatePlainTextFromHtml () {
            // Get HTML from emailCampaign.
            const html = this.emailCampaign.bodyHtml;

            // Start loader.
            this.generatingBodyPlain = true;

            // Send request.
            NUtilitiesService.convertHtmlToPlainText(html).then(({ data }) => {
                this.formFields.bodyPlain = data.plainText;
            }).catch((ignoredError) => {
                this.toastError('Conversion of HTML to Plain-Text failed');
            }).finally(() => {
                // Stop loader.
                this.generatingBodyPlain = false;
            });
        },

        // Body Plain Text Handlers //////////
        openPlainTextEditor () {
            this.$refs.customBodyPlainModalRef.open();
        },
        closePlainTextEditor () {
            this.$refs.customBodyPlainModalRef.close();
        }
    }
};
</script>

<style lang="scss">
    .EmailCampaignEditContentPlain {

        .sweet-modal-overlay {
            background: rgba(36,28,21,0.5) !important;
        }
        .sweet-title {
            background: #efeeea;
        }

        .sweet-modal,
        .sweet-content,
        .sweet-content-content,
        .sweet-buttons {
            text-align: unset !important;
        }

        .sweet-content {
            padding-top: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-bottom: 0 !important;
        }

        .editor-plain-text-textarea {
            padding: 3px 3px 3px 6px;
            position: relative;
            border: 0;
            width: 100%;
            min-height: 300px;
            max-height: 500px;
            overflow: auto;
            resize: none;
        }
    }
</style>

<i18n>
{
    "en": {
        "Edit Plain-Text version": "Edit Plain-Text version",
        "Save": "Save",
        "Cancel": "Cancel",
        "Generate from HTML": "Generate from HTML"
    },
    "el": {
        "Edit Plain-Text version": "Επεξεργασία έκδοσης Απλού-Κειμένου (Plain-Text)",
        "Save": "Αποθήκευση",
        "Cancel": "Ακύρωση",
        "Generate from HTML": "Αυτόματη παραγωγή από τη σχεδίαση"
    }
}
</i18n>
