<script>
import { mapGetters } from 'vuex';

/**
 * EmailCampaignEditMixin : Provides common functionality for EmailCampaign view editors and previewers.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 */
export default {
    name: 'EmailCampaignEditMixin',
    computed: {
        ...mapGetters('emailCampaign', {
            // UI sub-module //////////
            editorOpen: 'editorOpen',
            editorOpenDisabled: 'editorOpenDisabled',
            editorOverlayActive: 'editorOverlayActive',

            // Validation V0 sub-module //////////
            uiIconClassBasedOnValidationResult: 'uiIconClassBasedOnValidationResult'
        })
    },
    methods: {
        openEdit (editor) {
            this.$store.commit('emailCampaign/setCurrentEditor', editor);
        },
        saveEdit () {
            return this.$store.dispatch('emailCampaign/updateEmailCampaign')
                .then(() => {
                    // Reset currentEditor.
                    this.$store.commit('emailCampaign/setCurrentEditor', null);
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },
        closeEdit () {
            // We do not discard changes on emailCampaign instance because we never change it.
            // Reset currentEditor.
            this.$store.commit('emailCampaign/setCurrentEditor', null);
        }
    }
};
</script>
