<template>
    <div class="SummaryStatisticsV1">
        <element-loading :active="loading"></element-loading>
        <div class="gk-card text-center mb-0 border-bottom-0" style="background: #fafdfd;" v-if="inProgress">
            <p class="mb-0 small text-uppercase">
                <span class="dot-separator">{{ $t('In Progress') }}</span>
                <span class="dot-separator">{{ $t('sent_with_num', { num: specificFieldValue('send_unique') }) }}</span>
                <span class="dot-separator text-danger" v-if="specificFieldValue('reject_unique') > 0">{{ $t('failed_with_num', { num: specificFieldValue('reject_unique') }) }}</span>
            </p>
        </div>
        <div class="gk-card text-center mb-0 border-bottom-0">
            <div class="statistic-group">
                <div class="statistic">
                    <div class="value">{{ totalRecipients }}</div>
                    <div class="label">{{ $t('Recipients') }}</div>
                </div>
                <div class="statistic s-small">
                    <div class="value">{{ specificFieldValue('delivery_unique') }}</div>
                    <div class="label">{{ $t('Delivered') }}</div>
                </div>
            </div>
        </div>
        <div class="gk-card text-center mb-0 border-bottom-0">
            <div class="statistic-group">
                <div class="statistic s-small">
                    <div class="value">{{ specificFieldValue('open_unique') }}</div>
                    <div class="label">
                        <p class="mb-0" v-html="$t('Unique Opens')"></p>
                        <p class="mb-0">{{ specificFieldValue('open') }} {{ $t('totals') }}</p>
                    </div>
                </div>
                <div class="statistic s-small">
                    <div class="value">{{ specificFieldValue('campaignWebLinkClick_unique') }}</div>
                    <div class="label">
                        <p class="mb-0" v-html="$t('Unique Clicks')"></p>
                        <p class="mb-0">{{ specificFieldValue('campaignWebLinkClick') }} {{ $t('totals') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="gk-card text-center mb-0 border-bottom-0">
            <div class="statistic-group">
                <div class="statistic horizontal mini">
                    <div class="value mr-1">{{ specificFieldValue('bounce_unique') }}</div>
                    <div class="label">{{ $t('Bounces') }}</div>
                </div>
                <div class="statistic horizontal mini">
                    <div class="value mr-1">{{ specificFieldValue('complaint_unique') }}</div>
                    <div class="label">{{ $t('Complaints') }}</div>
                </div>
            </div>
        </div>
        <div class="gk-card mb-0 p-0 border-top-0 text-center">
            <span class="text-microcopy--70" v-if="loading">{{ $t('Loading') + '...' }}</span>
            <span class="text-microcopy--70 cursor-pointer" @click="retrieveAnalytics" v-else><i class="fas fa-sync-alt"></i> {{ $t('Refresh') }}</span>
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import { AnalyticsFieldValueCounterService } from '@/common/services/api.service';

/**
 * Basic Email Campaign Results as .statistic.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 15 July 2020
 * @lastModifiedAt 19 March 2021
 */
export default {
    name: 'SummaryStatisticsV1',
    props: {
        emailCampaignId: {
            type: String,
            required: true
        },
        completionTimestamp: {
            type: String,
            required: false,
            default: null
        },
        inProgress: {
            type: Boolean,
            default: false,
            required: false
        },
        totalRecipients: {
            type: Number,
            default: 0,
            required: true
        }
    },
    data () {
        return {
            interval: null,
            loading: false,
            error: null,
            fieldValueCounter: null,
            strokeWidth: 10
        };
    },
    computed: {
        // It's not very practical. 'send' and 'reject' counter are inc after an event occurs.
        // We need the final number of recipients in the beginning.
        /*
        totalRecipients () {
            // @future : Get recipients from template... and add emails in progress....
            let sendTotal = get(this.fieldValueCounter, 'values.send_unique', 0);
            let failTotal = get(this.fieldValueCounter, 'values.reject_unique', 0);
            return sendTotal + failTotal;
        }
        */
    },
    mounted () {
        this.retrieveAnalytics();

        const nowTime = new Date().getTime();
        let completedTime;
        if (this.completionTimestamp === null) {
            completedTime = nowTime;
        } else {
            completedTime = new Date(this.completionTimestamp).getTime();
        }
        if ((nowTime - completedTime) < 3600000) { // @future determine based on recipients count.
            this.displayRefreshButton = false;
            this.interval = setInterval(function () {
                this.retrieveAnalyticsSilentlyAndCheckInterval();
            }.bind(this), 15000); // @future determine based on recipients count.
        }
    },
    beforeDestroy () {
        clearInterval(this.interval);
    },
    methods: {
        specificFieldValue (name) {
            let key = 'values.' + name;
            return get(this.fieldValueCounter, key, 0);
        },
        specificFieldValuePerc (name) {
            // let sendTotal = get(this.fieldValueCounter, 'values.send_unique', 0);
            // let failTotal = get(this.fieldValueCounter, 'values.reject_unique', 0);
            // let total = sendTotal + failTotal;
            let total = this.totalRecipients;
            let val = get(this.fieldValueCounter, 'values.' + name, 0);
            if (total === 0) return 0.00;
            return parseFloat(((val * 100) / total).toFixed(2));
        },
        getAnalyticsName () {
            // @future : Use a reset controller that has knowledge of business logic.
            let tenantAccountId = this.$store.getters['application/tenantAccountId'];
            if (tenantAccountId === null) {
                tenantAccountId = 'default';
            }
            return tenantAccountId + '.' + 'emailCampaign::' + this.emailCampaignId;
        },
        retrieveAnalytics () {
            // Start loader.
            this.loading = true;
            const name = this.getAnalyticsName();
            AnalyticsFieldValueCounterService.retrieve(name)
                .then(({ data }) => {
                    this.fieldValueCounter = data;
                })
                .catch((error) => {
                    this.error = error;
                    this.fieldValueCounter = null;
                })
                .finally(() => {
                    // Stop loader.
                    this.loading = false;
                });
        },
        retrieveAnalyticsSilentlyAndCheckInterval () {
            const name = this.getAnalyticsName();
            AnalyticsFieldValueCounterService.retrieve(name)
                .then(({ data }) => {
                    const deliveryUnique = get(data, 'values.delivery_unique', 0);
                    if (deliveryUnique === this.totalRecipients) {
                        this.displayRefreshButton = true;
                        clearInterval(this.interval);
                    }
                    this.fieldValueCounter = data;
                })
                .catch((error) => {
                    this.error = error;
                    this.fieldValueCounter = null;
                })
                .finally(() => {
                    // Stop loader.
                    this.loading = false;
                });
        }
    }
};
</script>

<style lang="scss">
    .SummaryStatisticsV1 {

        .statistic-group {
            margin: 0;
        }

        // @future very useful. Add to styles.
        .dot-separator:not(:last-child):after {
            content: "•";
            color: #717171;
            padding: 0 .25rem;
        }
    }
</style>

<i18n>
{
    "en": {
        "In Progress": "In Progress",
        "Recipients": "Recipients",
        "Total": "Total",
        "Sent": "Sent",
        "Delivered": "Delivered",
        "Failed": "Failed",
        "Unique Opens": "Unique<br/>Opens",
        "total opens": "total opens",
        "Unique Clicks": "Unique<br/>Clicks",
        "total clicks": "total clicks",
        "Bounces": "Bounces",
        "Complaints": "Complaints",
        "Loading": "Loading",
        "Refresh": "Refresh",
        "totals": "Total",
        "sent_with_num": "{num} sent",
        "failed_with_num": "{num} failed"
    },
    "el": {
        "In Progress": "Σε Εξέλιξη",
        "Recipients": "Παραλήπτες",
        "Total": "Σύνολο",
        "Sent": "Αποστολές",
        "Delivered": "Παραδόσεις",
        "Failed": "Αποτυχίες",
        "Unique Opens": "Μοναδικές<br/>Αναγνώσεις",
        "total opens": "συνολικές αναγνώσεις",
        "Unique Clicks": "Μοναδικά<br/>Κλικ",
        "total clicks": "συνολικά κλικ",
        "Bounces": "Bounces",
        "Complaints": "Αναφορές",
        "Loading": "Φορτώνει",
        "Refresh": "Ανανέωση",
        "totals": "Συνολικά",
        "sent_with_num": "{num} στάλθηκαν",
        "failed_with_num": "{num} απέτυχαν"
    }
}
</i18n>
