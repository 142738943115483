<template>
    <div class="EmailCampaignSentNavigation bg-white border-bottom">
        <div class="container">
            <ul class="nav nav-pills HorizontalNavPills HorizontalNavPills--Bordered justify-content-start">
                <li class="nav-item">
                    <router-link tag="a"
                                 class="text-sm-center nav-link"
                                 :class="{ 'active': isViewActive('summary') }"
                                 :to="{ query: { view: 'summary' } }">
                        <span>{{ $t('Summary') }}</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link tag="a"
                                 class="text-sm-center nav-link"
                                 :class="{ 'active': isViewActive('preview') }"
                                 :to="{ query: { view: 'preview' } }">
                        <span>{{ $t('Preview') }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
/**
 * EmailCampaignSentNavigation : Navigation for non-draft Email Campaigns
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 15 July 2020
 * @lastModifiedAt 7 November 2020 (added li.nav-item)
 */
export default {
    name: 'EmailCampaignSentNavigation',
    data () {
        return {
            activeView: null
        };
    },
    beforeMount () {
        // The listen to changes and use mutations to modify property.
        this.$watch('$route', {
            handler: function (to) {
                let view = to.query.view;
                if (view === undefined || view === null) view = 'summary';
                this.activeView = view;
            },
            immediate: true
        });
    },
    methods: {
        isViewActive (view) {
            return view === this.activeView;
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Summary": "Summary",
        "Preview": "Preview"
    },
    "el": {
        "Summary": "Σύνοψη",
        "Preview": "Προεπισκόπηση"
    }
}
</i18n>
