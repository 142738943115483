<template>
    <div class="emoji-list">
        <div>
            <div v-for="(emojiGroup, category) in emojis" :key="category">
                <h5>{{ category }}</h5>
                <div class="emojis mb-4">
                    <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)" :title="emojiName">{{ emoji }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmojiData from './EmojiData';

export default {
    name: 'EmojiList',
    computed: {
        emojis () {
            return EmojiData;
        }
    },
    methods: {
        insert (emoji) {
            this.$emit('insert', emoji);
        }
    }
};
</script>

<style lang="scss" scoped>
    .emoji-list {

        h5 {
            font-weight: bold;
            margin-bottom: 0;
            color: #b1b1b1;
            text-transform: uppercase;
            font-size: 0.7rem;
            letter-spacing: 0.4px;
            cursor: default;
        }

        .emojis {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &:after {
                content: "";
                flex: auto;
            }

            span {
                padding: 0.2rem;
                cursor: pointer;
                border-radius: 5px;

                &:hover {
                    background: #ececec;
                    cursor: pointer;
                }
            }
        }
    }
</style>
