<template>
    <div class="EmailCampaignResultsHeader border-bottom">
        <div class="container">
            <div class="py-3">
                <p class="mb-0 text-microcopy--60 font-weight-bold">{{ $t('EMAIL CAMPAIGN') }}</p>
                <p class="size-5 weight-7 line-height-2 mb-0">{{ emailCampaign.name | ellipsis(64) }}</p>
                <p class="mb-0 mt-1 text-microcopy--70 text-muted">{{ $t('Sent on') }} {{ emailCampaign.sentTimestamp | dateLiteral }} {{ $t('by') }} {{ emailCampaign.sentBy }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmailCampaignEditMixin from '@/modules/EmailCampaign/mixins/EmailCampaignEditMixin';

/**
 * EmailCampaignResultsHeader : EmailCampaignView Header for results.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 * @lastModifiedAt 15 July 2020
 */
export default {
    name: 'EmailCampaignResultsHeader',
    mixins: [
        EmailCampaignEditMixin
    ],
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaign: 'emailCampaign'
        })
    }
};
</script>

<i18n>
{
    "en": {
        "EMAIL CAMPAIGN": "EMAIL CAMPAIGN",
        "Sent on": "Sent on",
        "by": "by"
    },
    "el": {
        "EMAIL CAMPAIGN": "ΕΚΣΤΡΑΤΕΙΑ EMAIL",
        "Sent on": "Στάλθηκε στις",
        "by": "από"
    }
}
</i18n>
