<template>
    <div class="EmailCampaignSentSummary my-4">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
                <summary-statistics-v1 :email-campaign-id="emailCampaign.id"
                                       :completion-timestamp="emailCampaign.completionTimestamp"
                                       :in-progress="emailCampaign.status === 'IN_PROGRESS'"
                                       :total-recipients="emailCampaign.validRecipientsCount"></summary-statistics-v1>
            </div>
            <div class="col-12 col-md-6 col-lg-6 mt-4 mt-md-0">
                <div class="gk-card mb-0">
                    <div class="FieldValueDisplay">
                        <p class="title">{{ $t('Broadcaster') }}</p>
                        <p class="value">
                            {{ emailCampaign.broadcaster.name }}
                            <br/>
                            {{ emailCampaign.broadcaster.emailCampaignFromName }} - {{ emailCampaign.broadcaster.emailCampaignFromEmail }}
                            <br/>
                            <small>{{ $t('Reply To') }}: {{ emailCampaign.broadcaster.emailCampaignReplyToName }} - {{ emailCampaign.broadcaster.emailCampaignReplyToEmail }}</small>
                        </p>
                    </div>
                    <div class="FieldValueDisplay">
                        <p class="title">{{ $t('Subject') }}</p>
                        <p class="value">{{ emailCampaign.subject }}</p>
                    </div>
                    <div class="FieldValueDisplay">
                        <p class="title">{{ $t('Workspace') }}</p>
                        <p class="value">{{ emailCampaign.workspace.name }}</p>
                    </div>
                    <div class="FieldValueDisplay" v-if="emailCampaign.completionTimestamp">
                        <p class="title">{{ $t('Completion Date') }}</p>
                        <p class="value">{{ emailCampaign.completionTimestamp | dateLiteral }}</p>
                    </div>
                    <div class="FieldValueDisplay">
                        <p class="title">{{ $t('Send Date') }}</p>
                        <p class="value">{{ emailCampaign.sentTimestamp | dateLiteral }}</p>
                    </div>
                    <div class="FieldValueDisplay mb-0">
                        <p class="title">{{ $t('Created Date') }}</p>
                        <p class="value">{{ emailCampaign.meta.createdDate | dateLiteral }}</p>
                    </div>
                    <!--
                    <div class="FieldValueDisplay">
                        <p class="title">{{ $t('Last Modified Date') }}</p>
                        <p class="value">{{ emailCampaign.meta.lastModifiedDate | dateLiteral }}</p>
                    </div>
                    -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SummaryStatisticsV1 from '@/modules/EmailCampaign/components/SummaryStatisticsV1';

/**
 * Summary for a non-draft EmailCampaign.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 15 July 2020
 * @lastModifiedAt 19 March 2021
 */
export default {
    name: 'EmailCampaignSentSummary',
    components: {
        SummaryStatisticsV1
    },
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaign: 'emailCampaign'
        })
    }
};
</script>

<i18n>
{
    "en": {
        "Broadcaster": "Broadcaster",
        "Reply To": "Reply To",
        "Subject": "Subject",
        "Workspace": "Workspace",
        "Completion Date": "Completion Date",
        "Send Date": "Send Date",
        "Created Date": "Created Date",
        "Last Modified Date": "Last Modified Date"
    },
    "el": {
        "Broadcaster": "Μεταδότης",
        "Reply To": "Απάντηση σε",
        "Subject": "Θέμα",
        "Workspace": "Χώρος Εργασίας",
        "Completion Date": "Ημερομηνία και ώρα ολοκλήρωσης",
        "Send Date": "Ημερομηνία και ώρα αποστολής",
        "Created Date": "Ημερομηνία και ώρα δημιουργίας",
        "Last Modified Date": "Ημερομηνία και ώρα επεξεργασίας"
    }
}
</i18n>
