<template>
    <div class="EmailCampaignEditUnitBalance">
        <div class="alert alert-warning C-Alert mb-4" v-if="displayAlert">
            <p class="mb-0 weight-5">
                {{ $t('Insufficient Balance') }}
                <small class="ml-2">
                    {{ $tc('email_alt_1', unitBalanceBalance, { count: unitBalanceBalance }) }}
                    <span class="mx-1">/</span>
                    {{ $tc('recipient_alt_1', workspaceCountSubscribed, { count: workspaceCountSubscribed }) }}
                </small>
            </p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * EmailCampaignEditUnitBalance : Displays insufficient unit balance.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 4 April 2020
 */
export default {
    name: 'EmailCampaignEditUnitBalance',
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaignUpdating: 'emailCampaignUpdating',
            workspaceCountSubscribed: 'workspaceCountSubscribed',
            unitBalanceBalance: 'unitBalanceBalance',
            validationResultIndeterminate: 'validationResultIndeterminate'
        }),
        displayAlert () {
            return this.validationResultIndeterminate('unitBalanceValid') === false;
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Insufficient Balance": "Insufficient Email Unit Balance",
        "email_alt_1": "no email units | one email unit | {count} email units",
        "recipient_alt_1": "no recipients | one recipient | {count} recipients"
    },
    "el": {
        "Insufficient Balance": "Ανεπαρκής Ισορροπία μονάδων email",
        "email_alt_1": "δεν υπάρχουν μονάδες email | μία μονάδα email | {count} μονάδες email",
        "recipient_alt_1": "δεν υπάρχουν παραλήπτες | ένας παραλήπτης | {count} παραλήπτες"
    }
}
</i18n>
