<template>
    <div class="EmailCampaignEditHeader border-bottom">
        <div class="container">
            <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center py-3">
                <div>
                    <p class="mb-0 text-microcopy--60 font-weight-bold">{{ $t('EMAIL CAMPAIGN') }}</p>
                    <p class="size-5 weight-7 line-height-2 mb-0">{{ emailCampaign.name | ellipsis(64) }}</p>
                    <p class="mb-0 mt-1 text-microcopy--70 text-muted">
                        <ring size="14" :color="'#1070ca'" v-if="emailCampaignUpdating"></ring> {{ $t('Edited on') }} {{ emailCampaign.meta.lastModifiedDate | dateLiteral }} {{ $t('by') }} {{ emailCampaign.meta.lastModifiedBy }}
                    </p>
                </div>
                <div class="mt-2 mt-md-0 d-flex flex-row justify-content-between align-items-center">
                    <button class="btn btn-secondary mr-2" @click="openSendTestConfirmation" :disabled="!validationResult('safeToSend')">{{ $t('Test') }}</button>
                    <button class="btn btn-accent1" @click="openSendConfirmation" :disabled="!validationResult('safeToSend')">{{ $t('Send') }}</button>
                </div>
            </div>
        </div>

        <!-- TEST CONFIRMATION MODAL -->
        <sweet-modal :blocking="true"
                     :pulse-on-block="true"
                     :hide-close-button="false"
                     modal-theme="light"
                     overlay-theme="dark"
                     ref="sendTestConfirmationDialogRef">
            <element-loading :active="emailCampaignSendingTest"></element-loading>
            <div slot="title">
                <p class="mb-0 weight-5 size-6">{{ $t('Test Email Campaign') }}</p>
            </div>
            <div class="position-relative text-left">
                <label for="emailListText" class="font-weight-bold">{{ $t('Send test to:') }}</label>
                <textarea
                    id="emailListText"
                    v-model="emailListText"
                    class="form-control mb-1"></textarea>
                <p class="text-muted small">{{ $t('send_test_use_commas_help_test') }}</p>
                <ul class="mb-0">
                    <li
                        v-for="email in emailList"
                        :key="email"
                    >
                        {{ email }}
                    </li>
                </ul>
            </div>
            <div slot="button">
                <div>
                    <button class="btn btn-secondary" @click="sendTestEmailCampaign">{{ $t('Send Test') }}</button>
                    <button class="btn btn-link" @click="closeSendTestConfirmation">{{ $t('Cancel') }}</button>
                </div>
            </div>
        </sweet-modal>

        <!-- CONFIRMATION MODAL -->
        <sweet-modal :blocking="true"
                     :pulse-on-block="true"
                     :hide-close-button="false"
                     modal-theme="light"
                     overlay-theme="dark"
                     ref="sendConfirmationDialogRef">
            <div slot="title">
                <p class="mb-0 weight-5 size-6">{{ $t('Prepare for launch') }}</p>
            </div>
            <div class="position-relative text-center">
                <p class="mb-1">{{ $t('You \'re about to send an Email Campaign to') }}:</p>
                <p class="mb-2 weight-5">{{ $tc('Person', workspaceCountSubscribed, { count: workspaceCountSubscribed }) }}</p>
                <p class="mb-0 font-weight-bolder small">{{ $t('cannot_be_stopped_notice') }}</p>
                <!-- @see https://mailchimp.com/help/why-we-cant-stop-or-edit-sent-campaigns/ and write in docs -->
            </div>
            <div slot="button">
                <div>
                    <button class="btn btn-secondary" @click="sendEmailCampaign">{{ $t('Send') }}</button>
                    <button class="btn btn-link" @click="closeSendConfirmation">{{ $t('Cancel') }}</button>
                </div>
            </div>
        </sweet-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmail from 'validator/es/lib/isEmail';
import { SweetModal } from 'sweet-modal-vue';
import Ring from '@/components/loading/Loaders/Ring';
import DomainModel from '@/common/logic/domain-models';
import { LegacyGpUiUserRecordService } from '@/common/services/api.service';
import EmailCampaignEditMixin from '@/modules/EmailCampaign/mixins/EmailCampaignEditMixin';

const LEG_GP_UI_USER_PREF_KEY = 'email_campaign_test_previous_emails_for_test';

/**
 * EmailCampaignEditHeader : EmailCampaignView Header for edit.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 * @lastModifiedAt 19 March 2021
 */
export default {
    name: 'EmailCampaignEditHeader',
    components: {
        SweetModal,
        Ring
    },
    mixins: [
        EmailCampaignEditMixin
    ],
    data () {
        return {
            emailListText: ''
        };
    },
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaign: 'emailCampaign',
            emailCampaignUpdating: 'emailCampaignUpdating',
            workspaceCountSubscribed: 'workspaceCountSubscribed',
            validationResult: 'validationResult',
            emailCampaignSendingTest: 'emailCampaignSendingTest'
        }),
        emailList () {
            if (typeof this.emailListText !== 'string') return [];
            const temp = this.emailListText.trim();
            if (temp === '') return [];
            return temp.split(/[\s,]+/)
                .map(function (i) {
                    return i.trim().toLowerCase();
                })
                .filter(function (i) {
                    return isEmail(i);
                })
                .filter(function (value, index, self) {
                    return self.indexOf(value) === index;
                });
        }
    },
    beforeMount () {
        LegacyGpUiUserRecordService.ensuredGetLegacyGpUiUserRecord(LEG_GP_UI_USER_PREF_KEY)
            .then(({ data }) => {
                this.emailListText = data.stringList.join(', ');
            }).catch(() => void 0);
    },
    methods: {
        sendEmailCampaign () {
            // Close confirmation.
            this.closeSendConfirmation();

            // At this point, we assume that everything is okay and email campaign can be sent!
            this.$store.dispatch('emailCampaign/sendEmailCampaign').then((ignoredData) => {
                this.toastDefault('toast_email_campaign_in_progress');
                this.$router.replace({ name: 'email-campaign-redirect', params: { id: this.emailCampaign.id } });
            }).catch((reason) => {
                this.toastErrorFromError(reason);
            });
        },

        // Confirmation Dialog / Modal Handlers //////////
        openSendConfirmation () {
            this.$refs.sendConfirmationDialogRef.open();
        },
        closeSendConfirmation () {
            this.$refs.sendConfirmationDialogRef.close();
        },

        sendTestEmailCampaign () {
            const dto = DomainModel.getLegacyGpUiUserRecordDTO();
            dto.key = LEG_GP_UI_USER_PREF_KEY;
            dto.stringList = this.emailList;
            LegacyGpUiUserRecordService.saveLegacyGpUiUserRecord(dto).then(() => void 0).catch(() => void 0);

            // At this point, we assume that everything is okay and email campaign can be sent!
            this.$store.dispatch('emailCampaign/sendTestEmailCampaign', { emailList: this.emailList })
                .then((ignoredData) => {
                    this.toastDefault('toast_email_campaign_test_in_progress');
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                })
                .finally(() => {
                    this.closeSendTestConfirmation();
                });
        },

        // Confirmation Dialog / Modal Handlers //////////
        openSendTestConfirmation () {
            this.$refs.sendTestConfirmationDialogRef.open();
        },
        closeSendTestConfirmation () {
            this.$refs.sendTestConfirmationDialogRef.close();
        }
    }
};
</script>

<style lang="scss">
    .EmailCampaignEditHeader {

        .sweet-modal-overlay {
            background: rgba(36,28,21,0.5) !important;
        }
        .sweet-modal {
            max-width: 450px !important;
        }
        .sweet-title {
            background: #efeeea;
        }
        .sweet-buttons {
            text-align: center !important;
        }
    }
</style>

<i18n>
{
    "en": {
        "EMAIL CAMPAIGN": "EMAIL CAMPAIGN",
        "Edited on": "Edited on",
        "by": "by",
        "Send": "Send",
        "Send Test": "Send Test",
        "Cancel": "Cancel",
        "Prepare for launch": "Prepare for launch",
        "You 're about to send an Email Campaign to": "You 're about to send an Email Campaign to",
        "Person": "0 People | 1 Person | {count} People",
        "cannot_be_stopped_notice": "Once a campaign has been sent, it can't be stopped or edited.",
        "Test Email Campaign": "Test Email Campaign",
        "send_test_use_commas_help_test": "Use commas to separate multiple emails.",
        "Send test to:": "Send test to:",
        "Test": "Test"
    },
    "el": {
        "EMAIL CAMPAIGN": "ΕΚΣΤΡΑΤΕΙΑ EMAIL",
        "Edited on": "Επεξεργάστηκε στις",
        "by": "από",
        "Send": "Αποστολή",
        "Send Test": "Αποστολή Test",
        "Cancel": "Ακύρωση",
        "Prepare for launch": "Προετοιμαστείτε για εκτόξευση",
        "You 're about to send an Email Campaign to": " Πρόκειται να στείλετε μια Εκστρατεία Email σε",
        "Person": "0 Άτομα | 1 Άτομο | {count} Άτομα",
        "cannot_be_stopped_notice": "Από τη στιγμή που μία εκστρατεία αποσταλεί, δε μπορεί να σταματήσει ή να τροποποιηθεί.",
        "Test Email Campaign": "Δοκιμή Εκστρατείας Email",
        "send_test_use_commas_help_test": "Χρησιμοποιήστε το κόμμα (,) για να προσθέσετε περισσότερα από ένα email (μέχρι 10).",
        "Send test to:": "Αποστολή τεστ σε:",
        "Test": "Δοκιμή"
    }
}
</i18n>
