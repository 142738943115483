<template>
    <div class="EmailCampaignEdit my-4">
        <email-campaign-edit-unit-balance></email-campaign-edit-unit-balance>
        <email-campaign-edit-workspace></email-campaign-edit-workspace>
        <email-campaign-edit-broadcaster></email-campaign-edit-broadcaster>
        <email-campaign-edit-subject></email-campaign-edit-subject>
        <email-campaign-edit-content></email-campaign-edit-content>
        <email-campaign-edit-settings></email-campaign-edit-settings>
        <email-campaign-edit-actions></email-campaign-edit-actions>
    </div>
</template>

<script>
import EmailCampaignEditWorkspace from './EmailCampaignEditWorkspace';
import EmailCampaignEditBroadcaster from './EmailCampaignEditBroadcaster';
import EmailCampaignEditSubject from './EmailCampaignEditSubject';
import EmailCampaignEditContent from './EmailCampaignEditContent';
import EmailCampaignEditSettings from './EmailCampaignEditSettings';
import EmailCampaignEditActions from './EmailCampaignEditActions';
import EmailCampaignEditUnitBalance from './EmailCampaignEditUnitBalance';

/**
 * EmailCampaignEdit : Renders all editors and previewers for EmailCampaign.
 *
 * - Workspace Preview and Edit
 * - Broadcaster Preview and Edit
 * - Subject / Preview Text Preview and Edit
 * - Content (Body HTML and Body Plain) Preview and Edit
 * - Settings Preview and Edit
 *
 * The order of the components affects the UI.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 */
export default {
    name: 'EmailCampaignEdit',
    components: {
        EmailCampaignEditUnitBalance,
        EmailCampaignEditBroadcaster,
        EmailCampaignEditWorkspace,
        EmailCampaignEditSubject,
        EmailCampaignEditContent,
        EmailCampaignEditSettings,
        EmailCampaignEditActions
    }
};
</script>
