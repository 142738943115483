<template>
    <div class="EmailCampaignEditPreviewSection EmailCampaignEditActions mt-4 text-right">
        <span class="btn-basic-action-1 text-danger small" @click="deleteConfirmation">{{ $t('Delete Email Campaign') }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * EmailCampaignEditActions : Basic actions for EmailCampaignView.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 */
export default {
    name: 'EmailCampaignEditActions',
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaign: 'emailCampaign'
        })
    },
    methods: {
        deleteConfirmation () {
            let resp = confirm(this.$t('Delete Email Campaign?'));
            if (!resp) {
                return;
            }
            this.$store.dispatch('emailCampaign/deleteEmailCampaign').then(() => {
                this.toastDefault('Email Campaign deleted');
                this.$router.push({ name: 'campaigns' });
            }).catch((reason) => {
                this.toastErrorFromError(reason);
            });
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Delete Email Campaign": "Delete Email Campaign",
        "Delete Email Campaign?": "Delete Email Campaign?"
    },
    "el": {
        "Delete Email Campaign": "Διαγραφή Εκστρατείας Email",
        "Delete Email Campaign?": "Διαγραφή Εκστρατείας Email;"
    }
}
</i18n>
