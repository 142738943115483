<template>
    <div class="EmailCampaignEditPreviewSection EmailCampaignEditWorkspace">

        <!-- OVERLAY -->
        <div class="EmailCampaignEditPreviewSection-Overlay" v-if="editorOverlayActive('workspace')"></div>

        <!-- EDITOR -->
        <div class="gk-card mb-0 border-bottom-0" v-if="editorOpen('workspace')" key="EmailCampaignEditWorkspace-Edit">
            <div class="d-flex">
                <div>
                    <i class="far fa-check-circle text-muted"></i>
                </div>
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Recipients') }}</p>
                    <div class="mt-2">
                        <global-select :foreignType="'workspace'"
                                       :foreignIdentifier="formFields.workspaceId"
                                       :elem-placeholder="$t('Type to search')"
                                       :elem-loading-text="null"
                                       :elem-no-data-text="null"
                                       :elem-no-match-text="null"
                                       v-on:select="formFields.workspaceId = $event"></global-select>
                    </div>
                    <div class="my-2">
                        <button class="btn btn-secondary" @click="submitEdit" :disabled="disableSubmitEdit">{{ $t('Save') }}</button>
                        <button class="btn btn-link" @click="discardEdit">{{ $t('Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- PREVIEW -->
        <div class="gk-card mb-0 border-bottom-0" v-else key="EmailCampaignEditWorkspace-Preview">
            <div class="d-flex">
                <div>
                    <i v-bind:class="uiIconClassBasedOnValidationResult('workspaceValid')"></i>
                </div>
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Recipients') }}</p>
                    <div v-if="workspace" key="with-workspace">
                        <p class="mb-0 weight-4">{{ $t('All subscribed People from Workspace') }} <span class="weight-5">{{ workspace.name }}</span></p>
                        <p class="mb-0 weight-4 font-italic text-muted small">{{ $t('Total People') }}: {{ workspaceCountTotal }}</p>
                        <p class="mb-0 weight-4 font-italic text-muted small">
                            <abbr :title="$t('Email Campaign Recipients')">{{ $t('Subscribed People') }}: {{ workspaceCountSubscribed }}</abbr>
                        </p>
                    </div>
                    <p class="mb-0 weight-4 font-italic text-muted" v-else key="without-workspace">{{ $t('Who are you sending this campaign to?') }}</p>
                </div>
                <div class="C-D-None-md-Down">
                    <button class="btn btn-sm btn-secondary-alt" @click="openEdit('workspace')" :disabled="editorOpenDisabled()">{{ $t('Edit Recipients') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmailCampaignEditMixin from '@/modules/EmailCampaign/mixins/EmailCampaignEditMixin';

/**
 * EmailCampaignEditWorkspace : Workspace preview and edit for EmailCampaignView.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 */
export default {
    name: 'EmailCampaignEditWorkspace',
    mixins: [
        EmailCampaignEditMixin
    ],
    data () {
        return {
            // Form //////////
            formFields: {
                workspaceId: null
            }
        };
    },
    computed: {
        ...mapGetters('emailCampaign', {
            workspace: 'workspace',
            emailCampaign: 'emailCampaign',
            workspaceCountTotal: 'workspaceCountTotal',
            workspaceCountSubscribed: 'workspaceCountSubscribed'
        }),
        disableSubmitEdit () {
            return this.formFields.workspaceId === null;
        }
    },
    mounted () {
        // Map data.
        this.formFields.workspaceId = this.emailCampaign.workspaceId;
    },
    methods: {
        submitEdit () {
            // Validate data.
            // No validation in here.

            // Map data.
            // Update emailCampaign instance field in our case.
            this.$store.commit('emailCampaign/setEmailCampaignField', {
                field: 'workspaceId',
                value: this.formFields.workspaceId
            });

            // Call 'saveEdit' from Mixin.
            this.saveEdit();
        },
        discardEdit () {
            // Map data.
            this.formFields.workspaceId = this.emailCampaign.workspaceId;

            // Call 'closeEdit' from Mixin.
            this.closeEdit();
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Recipients": "Recipients",
        "Save": "Save",
        "Cancel": "Cancel",
        "All subscribed People from Workspace": "All subscribed People from Workspace",
        "Total People": "Total People",
        "Subscribed People": "Subscribed People",
        "Who are you sending this campaign to?": "Who are you sending this campaign to?",
        "Edit Recipients": "Edit Recipients",
        "Email Campaign Recipients": "Email Campaign Recipients"
    },
    "el": {
        "Recipients": "Παραλήπτες",
        "Save": "Αποθήκευση",
        "Cancel": "Ακύρωση",
        "All subscribed People from Workspace": "Όλα τα εγγεγραμμένα Άτομα από το Χώρο Εργασίας",
        "Total People": "Σύνολο Ατόμων",
        "Subscribed People": "Εγγεγραμμένα Άτομα",
        "Who are you sending this campaign to?": "Σε ποιους στέλνετε αυτήν την εκστρατεία;",
        "Edit Recipients": "Επεξεργασία Παραληπτών",
        "Email Campaign Recipients": "Παραλήπτες Εκστρατείας Email"
    }
}
</i18n>
