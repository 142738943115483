<template>
    <div class="EmailCampaignEditPreviewSection EmailCampaignEditBroadcaster">

        <!-- OVERLAY -->
        <div class="EmailCampaignEditPreviewSection-Overlay" v-if="editorOverlayActive('broadcaster')"></div>

        <!-- EDITOR -->
        <div class="gk-card mb-0 border-bottom-0" v-if="editorOpen('broadcaster')" key="EmailCampaignEditBroadcaster-Edit">
            <div class="d-flex">
                <div>
                    <i class="far fa-check-circle text-muted"></i>
                </div>
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Broadcaster') }}</p>
                    <div class="mt-2">
                        <global-select :foreignType="'broadcaster'"
                                       :foreignIdentifier="formFields.broadcasterId"
                                       :elem-placeholder="$t('Type to search')"
                                       :elem-loading-text="null"
                                       :elem-no-data-text="null"
                                       :elem-no-match-text="null"
                                       v-on:select="formFields.broadcasterId = $event"></global-select>
                    </div>
                    <div class="my-2">
                        <button class="btn btn-secondary" @click="submitEdit" :disabled="disableSubmitEdit">{{ $t('Save') }}</button>
                        <button class="btn btn-link" @click="discardEdit">{{ $t('Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- PREVIEW -->
        <div class="gk-card mb-0 border-bottom-0" v-else key="EmailCampaignEditBroadcaster-Preview">
            <div class="d-flex">
                <div>
                    <i v-bind:class="uiIconClassBasedOnValidationResult('broadcasterValid')"></i>
                </div>
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Broadcaster') }}</p>
                    <div v-if="broadcaster" key="with-broadcaster">
                        <p class="mb-0 weight-4">{{ broadcaster.emailCampaignFromName }}<span class="mx-1">·</span>{{ broadcaster.emailCampaignFromEmail }}</p>
                        <p class="mb-0 weight-4 small text-muted">{{ $t('Reply To') }}: {{ broadcaster.emailCampaignReplyToName }}<span class="mx-1">·</span>{{ broadcaster.emailCampaignReplyToEmail }}</p>
                    </div>
                    <p class="mb-0 weight-4 font-italic text-muted" v-else key="without-broadcaster">{{ $t('Who is sending this campaign?') }}</p>
                </div>
                <div class="C-D-None-md-Down">
                    <button class="btn btn-sm btn-secondary-alt" @click="openEdit('broadcaster')" :disabled="editorOpenDisabled()">{{ $t('Edit Broadcaster') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmailCampaignEditMixin from '@/modules/EmailCampaign/mixins/EmailCampaignEditMixin';

/**
 * EmailCampaignEditBroadcaster : Broadcaster preview and edit for EmailCampaignView.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 */
export default {
    name: 'EmailCampaignEditBroadcaster',
    mixins: [
        EmailCampaignEditMixin
    ],
    data () {
        return {
            // Form //////////
            formFields: {
                broadcasterId: null
            }
        };
    },
    computed: {
        ...mapGetters('emailCampaign', {
            broadcaster: 'broadcaster',
            emailCampaign: 'emailCampaign'
        }),
        disableSubmitEdit () {
            return this.formFields.broadcasterId === null;
        }
    },
    mounted () {
        // Map data.
        this.formFields.broadcasterId = this.emailCampaign.broadcasterId;
    },
    methods: {
        submitEdit () {
            // Validate data.
            // No validation in here.

            // Map data.
            // Update emailCampaign instance field in our case.
            this.$store.commit('emailCampaign/setEmailCampaignField', {
                field: 'broadcasterId',
                value: this.formFields.broadcasterId
            });

            // Call 'saveEdit' from Mixin.
            this.saveEdit();
        },
        discardEdit () {
            // Map data.
            this.formFields.broadcasterId = this.emailCampaign.broadcasterId;

            // Call 'closeEdit' from Mixin.
            this.closeEdit();
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Broadcaster": "Broadcaster",
        "Save": "Save",
        "Cancel": "Cancel",
        "Edit Broadcaster": "Edit Broadcaster",
        "Who is sending this campaign?": "Who is sending this campaign?",
        "Reply To": "Reply To"
    },
    "el": {
        "Broadcaster": "Μεταδότης",
        "Save": "Αποθήκευση",
        "Cancel": "Ακύρωση",
        "Edit Broadcaster": "Επεξεργασία Μεταδότη",
        "Who is sending this campaign?": "Ποιος στέλνει αυτή την εκστρατεία;",
        "Reply To": "Απάντηση σε"
    }
}
</i18n>
