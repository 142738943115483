<template>
    <div class="EmailCampaignEditPreviewSection EmailCampaignEditSubject">

        <!-- OVERLAY -->
        <div class="EmailCampaignEditPreviewSection-Overlay" v-if="editorOverlayActive('subject')"></div>

        <!-- EDITOR -->
        <div class="gk-card mb-0 border-bottom-0" v-if="editorOpen('subject')" key="EmailCampaignEditSubject-Edit">
            <div class="d-flex">
                <div>
                    <i class="far fa-check-circle text-muted"></i>
                </div>
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Subject') }}</p>
                    <div class="row mt-4">
                        <div class="col-8">
                            <el-form :model="formFields"
                                     :rules="formFieldsRules"
                                     ref="editingFormRef"
                                     :label-position="'top'" class="cltlr-form-label-style-1" @submit.native.prevent>
                                <el-form-item :label="$t('Subject')" prop="subject">
                                    <el-input autosize v-model="formFields.subject">
                                        <template slot="append">
                                            <el-popover placement="bottom" width="300" trigger="click">
                                                <div class="g-max-height-200px-w-o-a">
                                                    <emoji-list v-on:insert="insertEmojiToSubject"></emoji-list>
                                                </div>
                                                <span slot="reference" class="text-primary cursor-pointer"><i class="far fa-smile-beam"></i></span>
                                            </el-popover>
                                        </template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item :label="$t('Preview Text')" prop="previewText">
                                    <el-input autosize v-model="formFields.previewText">
                                        <template slot="append">
                                            <el-popover placement="bottom" width="300" trigger="click">
                                                <div class="g-max-height-200px-w-o-a">
                                                    <emoji-list v-on:insert="insertEmojiToPreviewText"></emoji-list>
                                                </div>
                                                <span slot="reference" class="text-primary cursor-pointer"><i class="far fa-smile-beam"></i></span>
                                            </el-popover>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div class="my-2">
                        <button class="btn btn-secondary" @click="submitEdit" :disabled="disableSubmitEdit">{{ $t('Save') }}</button>
                        <button class="btn btn-link" @click="discardEdit">{{ $t('Cancel') }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- PREVIEW -->
        <div class="gk-card mb-0 border-bottom-0" v-else key="EmailCampaignEditSubject-Preview">
            <div class="d-flex">
                <div>
                    <i v-bind:class="uiIconClassBasedOnValidationResult('subjectAndPreviewTextValid')"></i>
                </div>
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Subject') }}</p>
                    <div v-if="emailCampaign.subject" key="with-subject">
                        <p class="mb-0 weight-4">{{ emailCampaign.subject }}</p>
                        <p class="mb-0 weight-4 text-muted small" v-if="emailCampaign.previewText">{{ $t('Preview Text') }}: {{ emailCampaign.previewText }}</p>
                    </div>
                    <p class="mb-0 weight-4 font-italic text-muted" v-else key="without-subject">{{ $t('What\'s the subject line for this campaign?') }}</p>
                </div>
                <div class="C-D-None-md-Down">
                    <button class="btn btn-sm btn-secondary-alt" @click="openEdit('subject')" :disabled="editorOpenDisabled()">{{ $t('Edit Subject') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ElValidationUtils from '@/common/utils/el-validation.utils';
import EmojiList from '@/components/Shared/Emoji/EmojiList';
import EmailCampaignEditMixin from '@/modules/EmailCampaign/mixins/EmailCampaignEditMixin';

/**
 * EmailCampaignEditSubject : Subject and PreviewText preview and edit for EmailCampaignView.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 */
export default {
    name: 'EmailCampaignEditSubject',
    components: {
        EmojiList
    },
    mixins: [
        EmailCampaignEditMixin
    ],
    data () {
        return {
            // Form //////////
            formFields: {
                subject: '',
                previewText: ''
            },
            formFieldsRules: {
                subject: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(null, 150)
                ],
                previewText: [
                    ElValidationUtils.lengthValidator(null, 150)
                ]
            }
        };
    },
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaign: 'emailCampaign'
        }),
        disableSubmitEdit () {
            if (this.formFields.subject === null) {
                return true;
            }
            return this.formFields.subject.trim() === '';
        }
    },
    mounted () {
        // Map data.
        this.formFields.subject = this.emailCampaign.subject;
        this.formFields.previewText = this.emailCampaign.previewText;
    },
    methods: {
        submitEdit () {
            // Validate data.
            this.$refs['editingFormRef'].validate((valid) => {
                if (valid) {
                    // Map data.
                    this.$store.commit('emailCampaign/setEmailCampaignField', {
                        field: 'subject',
                        value: this.formFields.subject
                    });
                    this.$store.commit('emailCampaign/setEmailCampaignField', {
                        field: 'previewText',
                        value: this.formFields.previewText
                    });

                    // Call 'saveEdit' from Mixin.
                    this.saveEdit();
                }
            });
        },
        discardEdit () {
            // Map data.
            this.formFields.subject = this.emailCampaign.subject;
            this.formFields.previewText = this.emailCampaign.previewText;

            // Call 'closeEdit' from Mixin.
            this.closeEdit();
        },

        // Editing Handlers //////////
        insertEmojiToSubject (emoji) {
            this.formFields.subject = this.formFields.subject + emoji;
        },
        insertEmojiToPreviewText (emoji) {
            this.formFields.previewText = this.formFields.previewText + emoji;
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Subject": "Subject",
        "Save": "Save",
        "Cancel": "Cancel",
        "Preview Text": "Preview Text",
        "What's the subject line for this campaign?": "What's the subject line for this campaign?",
        "Edit Subject": "Edit Subject"
    },
    "el": {
        "Subject": "Θέμα",
        "Save": "Αποθήκευση",
        "Cancel": "Ακύρωση",
        "Preview Text": "Κείμενο Προεπισκόπησης",
        "What's the subject line for this campaign?": "Ποιο είναι το θέμα αυτής της εκστρατείας;",
        "Edit Subject": "Επεξεργασία Θέματος"
    }
}
</i18n>
