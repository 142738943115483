<template>
    <div class="EmailCampaignEditPreviewSection EmailCampaignEditContent">

        <!-- OVERLAY -->
        <div class="EmailCampaignEditPreviewSection-Overlay" v-if="editorOverlayActive('content')"></div>

        <!-- EDITOR -->
        <!-- Content edit takes place in EmailCampaignContent module -->

        <!-- PREVIEW -->
        <div class="gk-card">
            <div class="d-flex">
                <div>
                    <i v-bind:class="uiIconClassBasedOnValidationResult('contentValid')"></i>
                </div>
                <div class="flex-grow-1 mx-2">
                    <p class="mb-0 weight-5">{{ $t('Content') }}</p>
                    <div class="mt-2 text-center">
                        <email-campaign-edit-content-html-preview></email-campaign-edit-content-html-preview>
                    </div>
                </div>
                <div class="C-D-None-md-Down">
                    <router-link tag="button"
                                 class="btn btn-sm btn-secondary-alt"
                                 :to="{ name: 'email-campaign-content', params: { id: emailCampaign.id } }">
                        <span>{{ $t('Edit Design') }}</span>
                    </router-link>
                </div>
            </div>
            <div class="mt-2 small border-top">
                <div class="m-2">
                    <p class="mb-0" v-if="emailCampaign.settings.productBranding">
                        <i class="fas fa-exclamation-circle text-warning"></i> {{ $t('product_branding_notice') }}
                    </p>
                    <p class="mb-0" v-if="emailCampaign.settings.transparencyAndCompliance">
                        <i class="fas fa-exclamation-circle text-warning"></i> {{ $t('compliance_and_transparency_notice') }}
                    </p>
                    <p class="mb-0" v-if="validationResult('bodyPlainValid')" key="with-body-plain-valid">
                        <i class="far fa-check-circle text-success"></i> {{ $t('plain_text_enabled_notice') }}
                    </p>
                    <p class="mb-0" v-else key="with-body-plain-invalid">
                        <i class="fas fa-exclamation-circle text-warning"></i> {{ $t('plain_text_disabled_notice') }}
                    </p>
                </div>
            </div>
            <div class="mt-2 small border-top C-D-None-md-Down">
                <div class="m-2">
                    <email-campaign-edit-content-plain></email-campaign-edit-content-plain>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmailCampaignEditMixin from '@/modules/EmailCampaign/mixins/EmailCampaignEditMixin';
import EmailCampaignEditContentPlain from './EmailCampaignEditContentPlain';
import EmailCampaignEditContentHtmlPreview from './EmailCampaignEditContentHtmlPreview';

/**
 * Preview and edit HTML and plain-text versions of the EmailCampaign.
 *
 * - Body HTML is managed by the EmailCampaignContent module.
 * - Body Plain is managed by an inner dialog component.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 * @lastModifiedAt 8 November 2020 (bug)
 */
export default {
    name: 'EmailCampaignEditContent',
    mixins: [
        EmailCampaignEditMixin
    ],
    components: {
        EmailCampaignEditContentHtmlPreview,
        EmailCampaignEditContentPlain
    },
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaign: 'emailCampaign',
            validationResult: 'validationResult'
        })
    }
};
</script>

<i18n>
{
    "en": {
        "Content": "Content",
        "Edit Design": "Edit Design",
        "product_branding_notice": "Cloutlayer will automatically add the logo to email footer.",
        "compliance_and_transparency_notice": "Cloutlayer will automatically insert the \"transparency and compliance\" footer.",
        "plain_text_enabled_notice": "Plain-Text version of email is enabled.",
        "plain_text_disabled_notice": "Plain-Text body is empty. Plain-Text version of email will be disabled."
    },
    "el": {
        "Content": "Περιεχόμενο",
        "Edit Design": "Επεξεργασία Σχεδίασης",
        "product_branding_notice": "Το Cloutlayer θα προσθέσει αυτόματα το απαιτούμενο λογότυπο στο υποσέλιδο του email.",
        "compliance_and_transparency_notice": "Το Cloutlayer θα προσθέσει αυτόματα το υποσέλιδο \"διαφάνειας και συμμόρφωσης\".",
        "plain_text_enabled_notice": "Η έκδοση Απλού-Κειμένου (Plain-Text) είναι ενεργοποιημένη.",
        "plain_text_disabled_notice": "Το σώμα της έκδοσης Απλού-Κειμένου (Plain-Text) είναι άδειο. Η έκδοση Απλού-Κειμένου (Plain-Text) θα απενεργοποιηθεί."
    }
}
</i18n>
