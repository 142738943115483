<template>
    <div class="EmailCampaignStatusAndOutcome">
        <div class="mt-4" v-if="displayPreparationNotification">
            <div class="alert alert-info C-Alert">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="mb-0 weight-5">{{ $t('email_campaign_is_being_prepared') }}</p>
                </div>
            </div>
        </div>
        <div class="mt-4" v-if="emailCampaign.status === 'IN_PROGRESS'">
            <div class="alert alert-info-alt C-Alert">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="mb-0 weight-5">{{ $t('Email Campaign is in progress') }}. <i class="far fa-paper-plane"></i></p>
                    <p class="mb-0 font-weight-bolder small">{{ $tc('Recipient', emailCampaign.validRecipientsCount, { count: emailCampaign.validRecipientsCount }) }}</p>
                </div>
            </div>
        </div>
        <div class="mt-4" v-if="emailCampaign.outcome === 'FAIL'">
            <div class="alert alert-danger C-Alert">
                <p class="mb-0 weight-5">{{ $t('Email Campaign send failed') }}.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * EmailCampaignStatusAndOutcome : State and outcome for email campaigns that are in progress or completed with fail.
 *
 * [T E M P O R A R Y / D E P R E C A T E D] : This component will be replaced with special components.
 * A component based on state and outcome will render a special component that handles only one scenario.
 * For example, a component that displays real-time progress when email campaign is in progress.
 * For example, a component that displays information about a failed campaign with support and contact links.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 17 March 2020
 * @lastModifiedAt 19 March 2021
 */
export default {
    name: 'EmailCampaignStatusAndOutcome',
    computed: {
        ...mapGetters('emailCampaign', {
            emailCampaign: 'emailCampaign'
        })
    },
    data () {
        return {
            interval: null,
            displayPreparationNotification: false
        };
    },
    mounted () {
        this.startCheckingForPreparationStage();
    },
    beforeDestroy () {
        clearInterval(this.interval);
    },
    methods: {
        startCheckingForPreparationStage () {
            if (this.emailCampaign.sentTimestamp === null) {
                this.displayPreparationNotification = false;
                return;
            }
            if (this.emailCampaign.completionTimestamp !== null) {
                this.displayPreparationNotification = false;
                return;
            }

            const sentTimestamp = new Date(this.emailCampaign.sentTimestamp);

            const logicFunc = function () {
                const now = new Date();
                const diff = now.getTime() - sentTimestamp.getTime();
                const seconds = (diff / 1000).toFixed(1);
                this.displayPreparationNotification = seconds <= 130;
                if (this.displayPreparationNotification === false) {
                    this.stopInterval();
                }
            }.bind(this);

            this.interval = setInterval(logicFunc, 5000);
        },
        stopInterval () {
            clearInterval(this.interval);
        }
    }
};
</script>

<i18n>
{
    "en": {
        "Email Campaign is in progress": "Email Campaign is in progress",
        "Email Campaign send failed": "Email Campaign send failed",
        "Recipient": "0 Recipients | 1 Recipient | {count} Recipients"
    },
    "el": {
        "Email Campaign is in progress": "Η Εκστρατεία Email είναι σε εξέλιξη",
        "Email Campaign send failed": "Η αποστολή της Εκστρατείας Email απέτυχε",
        "Recipient": "0 Παραλήπτες | 1 Παραλήπτης | {count} Παραλήπτες"
    }
}
</i18n>
